import { Injectable } from "@angular/core";
import { OrderService } from "src/services/order/order.service";
import { PendingOrderService } from "src/services/pending-order/pending-order.service";
import { Order } from "src/services/order/order.interface";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OrderPromotionService {
  constructor(
    private readonly pendingOrderService: PendingOrderService,
    private readonly orderService: OrderService,
  ) {}

  public async promoteOrder(pendingOrderId: string): Promise<string> {
    const pendingOrder = await firstValueFrom(this.pendingOrderService.getById(pendingOrderId));

    const newOrderId = await this.orderService.set(pendingOrder as Order);

    await this.pendingOrderService.delete(pendingOrder.id);

    return newOrderId;
  }
}
