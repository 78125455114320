import { Routes } from "@angular/router";
import { fireAuthGuardUnauthorizedConfig } from "./routes-config.const";
import { driverPages } from "src/services/navigation/paths.const";
import { adminOrDriverGuards } from "./admin.routes";

export const driverRoutes: Routes = [
  {
    path: driverPages.orderStepsRoot,
    loadChildren: () => import("../redesign/driver/order-steps/order.steps.module").then((m) => m.OrderStepsModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
];
