import { kioskPages } from "src/services/navigation/paths.const";
import { Routes } from "@angular/router";
import { KioskGuard } from "src/services/router-guards/kiosk.guard";

export const kioskRoutes: Routes = [
  {
    path: kioskPages.choosePlan,
    loadChildren: () =>
      import("../redesign/kiosk/pages/kiosk-choose-plan/kiosk-choose-plan.module").then((m) => m.KioskChoosePlanModule),
    canActivate: [KioskGuard],
  },
  {
    path: kioskPages.confirmation,
    loadChildren: () =>
      import("../redesign/kiosk/pages/kiosk-confirmation/kiosk-confirmation.module").then(
        (m) => m.KioskConfirmationPageModule,
      ),
    canActivate: [KioskGuard],
  },
  {
    path: kioskPages.landing,
    loadChildren: () =>
      import("../redesign/kiosk/pages/kiosk-landing/kiosk-landing.module").then((m) => m.KioskLandingPageModule),
    canActivate: [KioskGuard],
  },
  {
    path: kioskPages.signUp,
    loadChildren: () =>
      import("../redesign/kiosk/pages/kiosk-sign-up/kiosk-sign-up.module").then((m) => m.KioskSignUpModule),
    canActivate: [KioskGuard],
  },
  {
    path: kioskPages.thankYou,
    loadChildren: () =>
      import("../redesign/kiosk/pages/kiosk-thank-you/kiosk-thank-you.module").then((m) => m.KioskThankYouPageModule),
    canActivate: [KioskGuard],
  },
];
