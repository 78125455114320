import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "@environments/environment";
import { isPlatform } from "@ionic/angular";

if (environment.production) {
  enableProdMode();
}

try {
  if (!(isPlatform("ios") || isPlatform("android"))) {
    const Sentry = require("@sentry/angular-ivy");
    Sentry.init({
      dsn: "https://6125b01237b546a4a9b88fb1ef56cb20@o4505581056032768.ingest.sentry.io/4505581057474560",
      environment: environment.production ? "production" : "dev",
      release: environment.production ? "production" : "dev",
    });
  }
} catch {}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
