export interface DataPage<DataType> {
  data: DataType[];
}

export function objectToQueryString(input: Record<string, string | string[]> | undefined): string {
  if (!input) {
    return "";
  }

  return Object.entries(input)
    .map(([key, val]) => `${key}=${Array.isArray(val) ? val.join(",") : val}`)
    .join("&");
}
