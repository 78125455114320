export const pathParams = {
  itemId: "id",
  orderId: "id",
  planId: "id",
  stripePlanId: "id",
  userId: "id",
};

export const queryParams = {
  coupon: "coupon",
  email: "email",
  frequency: "frequency",
  itemId: "itemId",
  loginMode: "login-mode",
  orderId: "orderId",
  planId: "planId",
  pickupOrderId: "orderId",
  scanMode: "scanMode",
  stripePlanId: "stripeId",
  userId: "userId",
};
