import { Routes } from "@angular/router";
import { fireAuthGuardUnauthorizedConfig, guardsForAuthenticatedPages } from "./routes-config.const";
import { IsAdminGuard } from "src/services/router-guards/is-admin.guard";
import { adminPages, manageNotificationsPage, userDetailPage } from "src/services/navigation/paths.const";
import { IsAdminOrDriverGuard } from "src/services/router-guards/is-admin-or-driver.guard";
import { pathParams } from "src/services/navigation/query-params.const";

const adminGuards = [...guardsForAuthenticatedPages, IsAdminGuard];
export const adminOrDriverGuards = [...guardsForAuthenticatedPages, IsAdminOrDriverGuard];

const orderRoutes: Routes = [
  {
    path: adminPages.allOrders,
    loadChildren: () => import("../pages/admin/all-orders/all-orders.module").then((m) => m.AllOrdersPageModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.customerOnly,
    loadChildren: () =>
      import("../redesign/admin/customer-only/customer-only.module").then((m) => m.CustomerOnlyModule),
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.itemsToHotel}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/items-to-hotel/items-to-hotel.module").then((m) => m.ItemsToHotelModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.itemsToOnboard}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/items-to-store/items-to-onboard.module").then((m) => m.ItemsToOnboardModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.itemsToStage}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/items-to-stage/items-to-stage.module").then((m) => m.ItemsToStageModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.itemsToVehicle}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/items-to-vehicle/items-to-vehicle.module").then((m) => m.ItemsToVehicleModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.orderDetails,
    loadChildren: () =>
      import("../redesign/admin/admin-order-detail/admin-order-detail.module").then((m) => m.AdminOrderDetailModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.orderDetails}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/admin-order-detail/admin-order-detail.module").then((m) => m.AdminOrderDetailModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.scanItems}/:${pathParams.orderId}`,
    loadChildren: () => import("../redesign/admin/scan/items/scan-item.module").then((m) => m.ScanItemsModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.scanRack,
    loadChildren: () => import("../redesign/admin/scan/rack/scan-rack.module").then((m) => m.ScanRackModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.scanVehicle,
    loadChildren: () => import("../redesign/admin/scan/vehicle/scan-vehicle.module").then((m) => m.ScanVehicleModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.stagingSummary}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/staging-summary/staging-summary.module").then((m) => m.StagingSummaryModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${adminPages.stagingSuccess}/:${pathParams.orderId}`,
    loadChildren: () =>
      import("../redesign/admin/staging-success/staging-success.module").then((m) => m.StagingSuccessModule),
    canActivate: adminOrDriverGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
];

export const adminRoutes: Routes = orderRoutes.concat([
  {
    path: "manage-user/:id",
    loadChildren: () => import("../pages/admin/manage-user/manage-user.module").then((m) => m.ManageUserPageModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: "manage-user",
    loadChildren: () => import("../pages/admin/manage-user/manage-user.module").then((m) => m.ManageUserPageModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: "all-users",
    loadChildren: () => import("../pages/admin/all-users/all-users.module").then((m) => m.AllUsersPageModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${userDetailPage}/:id`,
    loadChildren: () => import("../pages/admin/user-details/user-details.module").then((m) => m.UserDetailsPageModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.allItems,
    loadChildren: () => import("../pages/admin/items/all-items/all-items.module").then((m) => m.AllItemsPageModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.manageKioskMode,
    loadChildren: () =>
      import("../pages/admin/manage-kiosk-mode/manage-kiosk-mode.module").then((m) => m.ManageKioskModeModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: manageNotificationsPage,
    loadChildren: () =>
      import("../pages/admin/manage-notifications/manage-notifications.module").then(
        (m) => m.ManageNotificationsPageModule,
      ),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: adminPages.manageRacks,
    loadChildren: () =>
      import("../pages/admin/manage-locations/manage-locations.module").then((m) => m.ManageLocationsModule),
    canActivate: adminGuards,
    data: fireAuthGuardUnauthorizedConfig,
  },
]);
