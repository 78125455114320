import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { adminRoutes } from "./admin.routes";
import { redesignRoutes } from "./redesign.routes";
import { customerRoutes } from "./customer.routes";
import { kioskRoutes } from "./kiosk.routes";
import { driverRoutes } from "./driver.routes";

const notFound: Routes = [
  {
    path: "**",
    loadChildren: () => import("../redesign/customer/not-found/not-found.module").then((m) => m.NotFoundModule),
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      customerRoutes
        .concat(adminRoutes)
        .concat(driverRoutes)
        .concat(redesignRoutes)
        .concat(kioskRoutes)
        .concat(notFound),
      {
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
