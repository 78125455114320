import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { Subscription } from "src/services/subscription/subscription";
import { SubscriptionTypeService } from "../subscription-type/subscription-type.service";
import { BaseCollectionService } from "src/services/base-collection.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService extends BaseCollectionService<Subscription> {
  constructor(
    protected readonly firestore: AngularFirestore,
    private readonly subscriptionTypeService: SubscriptionTypeService,
  ) {
    super(firestore, environment.collections.subscriptions);
  }

  public async generateNewBasicSubscriptionFromPriceId(userId: string, priceId: string): Promise<Subscription> {
    const subscriptionType = await this.subscriptionTypeService.getSubscriptionTypeFromPriceId(priceId);
    const plan = this.subscriptionTypeService.getPlanWithPriceIdFromSubscriptionType(subscriptionType, priceId);

    return {
      baseLocation: undefined,
      price: plan.price,
      status: "Active",
      subscriptionDate: new Date(),
      interval: plan.interval,
      hasPromoCode: false,
      promoCode: "",
      userId: userId,
      subscriptionType: subscriptionType.name,
      subscriptionTypeId: subscriptionType.id,
    };
  }

  public userHasAnySubscriptionWithoutBase(userId: string): Observable<boolean> {
    return this.getByUserId(userId).pipe(
      map((subscriptions) => {
        return subscriptions.some((sub) => sub.baseSelectedByUser === false || !sub.baseLocation);
      }),
    );
  }
}
