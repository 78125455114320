import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "src/services/auth/auth.service";
import { SubscriptionService } from "src/services/subscription/subscription.service";
import { Role } from "src/services/user/role.enum";
import { firstValueFrom } from "rxjs";
import { redesignPages } from "src/services/navigation/paths.const";

@Injectable()
export class HasSelectedBaseGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly subscriptionService: SubscriptionService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = await this.authService.getActiveUser();

    const needsToSelectBase = await firstValueFrom(
      this.subscriptionService.userHasAnySubscriptionWithoutBase(currentUser.id),
    );

    if (needsToSelectBase && currentUser.role !== Role.admin) {
      await this.router.navigate([redesignPages.chooseBase]);
      return false;
    }

    return true;
  }
}
