import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "src/services/auth/auth.service";
import { Role } from "src/services/user/role.enum";
import { redesignPages } from "src/services/navigation/paths.const";
import { SubscriptionStatusService } from "../subscription/subscription-status.service";

@Injectable()
export class HasActiveSubscriptionGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly subscriptionStatusService: SubscriptionStatusService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = await this.authService.getActiveUser();

    if (currentUser.role === Role.admin || currentUser.role === Role.driver) {
      return true;
    } else {
      const subscriptionStatus = await this.subscriptionStatusService.getSubscriptionStatus(currentUser.id);
      if (subscriptionStatus === "active") {
        return true;
      } else {
        await this.router.navigate([redesignPages.choosePlan]);
        return false;
      }
    }
  }
}
