export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function debounce<T>(func: () => T, timeout = 300): () => void {
  let timer: any;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export class TimeoutError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export async function promiseWithTimeout<T>(promise: Promise<T>, delay: number, timeoutMessage: string): Promise<T> {
  const timeout = new Promise<T>((_, reject) => setTimeout(() => reject(new TimeoutError(timeoutMessage)), delay));

  return Promise.race([promise, timeout]);
}
