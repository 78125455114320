export interface Plan {
  interval: PlanFrequency;
  price: number;
  stripeId: string;
}

export type PlanName = "Small" | "Medium" | "Large";

export interface SubscriptionType {
  id: PlanUniqueId;
  name: PlanName;
  image?: string;
  plans: Plan[];
}

export enum PlanUniqueId {
  small = "PF3mVyztGVrDvOUVYLTK",
  medium = "MXgdu3M7bMyzhGHKxGtt",
  large = "REuK9bJWeclFMBJteUHD",
  discontinuedDoubleDiamond = "O4iA4NwOLOZrnMJtccGa",
  individualBootBag = "",
  individualBootBagWithDelivery = "",
  individualLargeSuitcase = "",
  individualLargeSuitcaseWithDelivery = "",
}

export enum PlanFrequency {
  annual = "annual",
  monthly = "monthly",
}
