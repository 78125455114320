import { Routes } from "@angular/router";
import { customerPages, myClosetPage, oldPages, redesignPages } from "src/services/navigation/paths.const";
import { HasValidSubscriptionGuard } from "../../services/router-guards/has-valid-subscription.guard";
import { fireAuthGuardUnauthorizedConfig, guardsForAuthenticatedPages } from "./routes-config.const";
import { KioskGuard } from "src/services/router-guards/kiosk.guard";
import { RedirectToDownloadAppOnDesktopGuard } from "src/services/router-guards/redirect-to-download-app-on.desktop.guard";
import { HasActiveSubscriptionGuard } from "../../services/router-guards/has-active-subscription.guard";

export const customerRoutes: Routes = [
  {
    path: "",
    redirectTo: redesignPages.homePage,
    pathMatch: "full",
  },
  {
    path: myClosetPage,
    loadChildren: () => import("../pages/closet/closet.module").then((m) => m.ClosetPageModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: oldPages.scheduleTrip,
    loadChildren: () => import("../pages/schedule-trip/schedule-trip.module").then((m) => m.ScheduleTripPageModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
      HasActiveSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: customerPages.adminOnly,
    loadChildren: () => import("../redesign/customer/admin-only/admin-only.module").then((m) => m.AdminOnlyModule),
    canActivate: [KioskGuard],
  },
  {
    path: customerPages.downloadTheApp,
    loadChildren: () =>
      import("../redesign/customer/download-app/download-app.module").then((m) => m.DownloadAppModule),
    canActivate: [KioskGuard],
  },
];
