<ion-app>
  <ion-menu contentId="main-content" type="reveal">
    <ion-header style="padding: 20px; background-color: var(--rc-very-light-grey)" class="ion-no-border">
      <ion-grid>
        <ion-grid>
          <ion-row class="no-padding">
            <ion-col>
              <h2 style="color: var(--rc-way-darker-grey)">
                <b>{{ userToString(activeUser) }}</b>
              </h2>
            </ion-col>
            <ion-col size="2">
              <ion-menu-toggle>
                <ion-button color="light"><ion-icon name="close-circle-outline"></ion-icon></ion-button>
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
          <ion-row class="no-padding">
            <p
              class="no-margin"
              style="color: var(--rc-way-darker-grey); font-size: 11px"
              *ngIf="activeUser?.createdAt">
              Member since {{ tryParseDate(activeUser?.createdAt) | date }}
            </p>
          </ion-row>
          <ion-row class="no-padding">
            <p style="color: var(--rc-way-darker-grey)" *ngIf="activeUserReferral?.referralPromoCode">
              Referral code
              <span class="highlight" *ngIf="activeUserReferral?.referralPromoCode">
                {{ activeUserReferral.referralPromoCode }}
              </span>
            </p>
          </ion-row>
        </ion-grid>
      </ion-grid>
    </ion-header>
    <ion-content>
      <ng-container *ngFor="let p of appPages; let i = index">
        <ion-menu-toggle *ngIf="p.url" auto-hide="false">
          <ion-item
            (click)="selectedIndex = i"
            [class.selected]="selectedIndex == i"
            [routerLink]="[p.url]"
            detail="false"
            lines="none"
            routerDirection="root">
            <h2>
              <b>{{ p.title }}</b>
            </h2>
          </ion-item>
        </ion-menu-toggle>
        <ion-item
          (click)="selectedIndex = i"
          *ngIf="p.children?.length > 0"
          [class.selected]="selectedIndex == i"
          lines="none">
          <p style="padding-left: 6px; padding-top: 6px">{{ p.title }}</p>
        </ion-item>

        <!-- Children List for clicked Item -->
        <ion-list class="no-padding">
          <ion-menu-toggle auto-hide="false">
            <div style="height: 100%; width: 100%; padding-left: 8px">
              <ion-item
                *ngFor="let sub of p.children"
                [routerLink]="[sub.url]"
                class="sub-item no-padding"
                detail="false"
                lines="none"
                routerDirection="root">
                <ion-label color="primary" style="font-size: 14px">
                  {{ sub.title }}
                </ion-label>
              </ion-item>
            </div>
          </ion-menu-toggle>
        </ion-list>
      </ng-container>
    </ion-content>

    <ion-footer>
      <ion-item
        *ngIf="showDeleteAccountRequest"
        (click)="requestDeleteAccountRequiredForAppleReviewProcess()"
        detail="false"
        lines="none"
        class="clickable">
        <ion-icon style="margin-right: 8px" ios="close-outline" md="log-out-outline" color="primary"></ion-icon>
        <ion-label style="font-size: 14px" color="primary">Request delete account</ion-label>
      </ion-item>
      <ion-item (click)="logout()" detail="false" lines="none" class="clickable">
        <ion-icon style="margin-right: 8px" ios="log-out-outline" md="log-out-outline" color="primary"></ion-icon>
        <ion-label style="font-size: 14px" color="primary">Logout</ion-label>
        <ion-text>v{{ environment.appVersion }}</ion-text>
      </ion-item>
    </ion-footer>
    <ion-footer style="padding: 5px; background-color: var(--rc-very-light-grey)">
      <div style="display: flex" class="ion-justify-content-center">
        <img alt="logo" src="assets/logo/dark_logo_header.png" style="height: 60px" />
      </div>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
