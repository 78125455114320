import { assertNever } from "../utils/enum.utils";
import { ApiOrderServiceType } from "../order/order-api.service";

export enum ServiceType {
  firstTimePickup = "First time pickup",
  baseToBaseDelivery = "Base to base delivery",
  localDelivery = "Local delivery",
  localPickup = "Local pickup",
  nationwideDelivery = "Nationwide delivery",
  nationwidePickup = "Nationwide pickup",
}

export const anyDeliveryType = [
  ServiceType.localDelivery,
  ServiceType.baseToBaseDelivery,
  ServiceType.nationwideDelivery,
];

export function isAnyDeliveryType(serviceType: ServiceType): boolean {
  return anyDeliveryType.includes(serviceType);
}

export const anyPickupType = [ServiceType.localPickup, ServiceType.firstTimePickup, ServiceType.nationwidePickup];

export function isAnyPickupType(serviceType: ServiceType): boolean {
  return anyPickupType.includes(serviceType);
}

export function planServiceTypeToShortLabel(serviceType: ServiceType | ApiOrderServiceType): string {
  switch (serviceType) {
    case "localDelivery":
    case "localPickup":
    case ServiceType.localDelivery:
    case ServiceType.localPickup:
      return serviceType.toString();

    case "baseToBaseDelivery":
    case ServiceType.baseToBaseDelivery:
      return "Base to base";

    case "firstTimePickup":
    case ServiceType.firstTimePickup:
      return "First time";

    case "nationwideDelivery":
    case ServiceType.nationwideDelivery:
      return "Nationwide Delivery";

    case "nationwidePickup":
    case ServiceType.nationwidePickup:
      return "Nationwide Pickup";
    default:
      return assertNever(serviceType);
  }
}
