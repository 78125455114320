import { Component, OnInit } from "@angular/core";
import Stripe from "stripe";
import { MessageService } from "src/services/message/message.service";
import { ActivatedRoute } from "@angular/router";
import { PaymentsService } from "src/services/payment/payments.service";
import { LoadingService } from "src/services/loading/loading.service";
import { firstValueFrom } from "rxjs";
import { User } from "src/services/user/user.interface";
import { AuthService } from "src/services/auth/auth.service";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { PendingOrderService } from "src/services/pending-order/pending-order.service";
import { PendingOrder } from "src/services/pending-order/pending-order.interface";
import { OrderPromotionService } from "src/services/order/order-promotion.service";
import { NavigationService } from "src/services/navigation/navigation.service";
import { isAnyPickupType } from "src/services/subscription/plan-service-type.enum";
import { BackendBody } from "../../../../shared/backend-api.types";

export const deliveryIdParam = "check-in";
export const pickupIdParam = "check-out";

export enum PaymentRequirement {
  free,
  required,
  deferred,
}

export type OrderInfo = {
  order?: PendingOrder;
  displayReason?: string;
  paymentRequirements: PaymentRequirement;
  price?: number;
};

@Component({
  templateUrl: "./pay-for-delivery.page.html",
  styleUrls: ["./pay-for-delivery.page.scss"],
})
export class PayForDeliveryPage implements OnInit {
  public activeUser: User;
  public handlingPaymentOfDelivery: boolean;
  public handlingPaymentOfPickup: boolean;
  public deliveryOrderInfo: OrderInfo = { paymentRequirements: PaymentRequirement.free };
  public cost: number = 0;
  public loading = true;
  public pickupOrderInfo: OrderInfo = { paymentRequirements: PaymentRequirement.free };
  public paymentMethods: Stripe.PaymentMethod[] = [];
  public pendingDeliveryOrderId: string;
  public pendingPickupOrderId: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly iab: InAppBrowser,
    private readonly loadingService: LoadingService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly orderPromotionService: OrderPromotionService,
    private readonly paymentsService: PaymentsService,
    private readonly pendingOrderService: PendingOrderService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.activeUser = await this.authService.getActiveUser();
    this.pendingDeliveryOrderId = this.activatedRoute.snapshot.queryParamMap.get(deliveryIdParam);
    this.pendingPickupOrderId = this.activatedRoute.snapshot.queryParamMap.get(pickupIdParam);

    this.handlingPaymentOfDelivery = !!this.pendingDeliveryOrderId;
    this.handlingPaymentOfPickup = !!this.pendingPickupOrderId;

    await this.loadingService.wrapInLoading(async () => {
      if (this.pendingDeliveryOrderId) {
        await this.computePaymentExpectationsForOrderId(this.pendingDeliveryOrderId, this.deliveryOrderInfo);
      }

      if (this.pendingPickupOrderId) {
        await this.computePaymentExpectationsForOrderId(this.pendingPickupOrderId, this.pickupOrderInfo);
      }

      this.loading = false;
    });
  }

  public async pay(): Promise<void> {
    try {
      const session = await this.paymentsService.createStripeCheckoutSessionForOrder(
        this.activeUser.id,
        this.pendingPickupOrderId,
        this.pendingDeliveryOrderId,
      );

      const browser = this.iab.create(session.url, "_self", { beforeload: "get" });
      browser.show();
    } catch (error) {
      await this.messageService.presentErrorToast(JSON.stringify(error ?? "Unexpected error"));
    }
  }

  public async promote(): Promise<void> {
    if (this.cost === 0) {
      if (this.pendingDeliveryOrderId) {
        await this.orderPromotionService.promoteOrder(this.pendingDeliveryOrderId);
      }

      if (this.pendingPickupOrderId) {
        await this.orderPromotionService.promoteOrder(this.pendingPickupOrderId);
      }

      await this.navigationService.goToDeliveriesPage();
    }
  }

  private async computePaymentExpectationsForOrderId(orderId: string, orderInfoToUpdate: OrderInfo): Promise<void> {
    const order = await firstValueFrom(this.pendingOrderService.getById(orderId));

    orderInfoToUpdate.order = order;
    const deliveryPaymentRequirement = await this.getPaymentRequirements(order);

    if (deliveryPaymentRequirement.paymentRequired) {
      this.cost += deliveryPaymentRequirement.price;
      orderInfoToUpdate.paymentRequirements = PaymentRequirement.required;
      orderInfoToUpdate.price = deliveryPaymentRequirement.price;
    } else {
      orderInfoToUpdate.paymentRequirements =
        deliveryPaymentRequirement.reason === "nationwideAlwaysRequirePayment"
          ? PaymentRequirement.deferred
          : PaymentRequirement.free;
    }

    orderInfoToUpdate.displayReason = await this.paymentsService.getPaymentRequiredReasonDisplayString(
      deliveryPaymentRequirement.reason,
    );
  }

  private getPaymentRequirements(order: PendingOrder): Promise<BackendBody["PaymentRequirement"]> {
    if (isAnyPickupType(order.serviceType) && (!order.items || order.items.length === 0)) {
      return this.paymentsService.checkIfPaymentRequiredForPickupOnly(order.serviceType);
    } else {
      return this.paymentsService.checkIfPaymentRequired(order.items, order.serviceType);
    }
  }
}
