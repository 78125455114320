import {
  ActiveSubscription,
  ActiveSubscriptionStatus,
  BackendBody,
  SubscriptionStatus,
} from "../../shared/backend-api.types";
import { apiPath } from "../../shared/api-path.const";

import { Injectable } from "@angular/core";
import { ApiService } from "../http/api.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionStatusService {
  constructor(private readonly apiService: ApiService) {}
  public async getAllActiveSubscriptions(userId: string): Promise<ActiveSubscription[]> {
    const response = await this.apiService.sendGetRequest<BackendBody["SubscriptionDataPage"]>(
      `${apiPath.subscription}/${userId}`,
      undefined,
    );

    return response.subscriptions;
  }

  public async getAllSubscriptionStatuses(): Promise<ActiveSubscriptionStatus[]> {
    const response = await this.apiService.sendGetRequest<BackendBody["ActiveSubscriptionDataPage"]>(
      apiPath.activeSubscriptions,
      undefined,
    );

    return response.activeSubscriptions;
  }

  public async getSubscriptionStatus(userId: string): Promise<SubscriptionStatus> {
    const response = await this.apiService.sendGetRequest<ActiveSubscriptionStatus>(
      `${apiPath.activeSubscriptions}/${userId}`,
      undefined,
    );

    return response.subscriptionStatus;
  }
}
