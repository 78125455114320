import { Injectable } from "@angular/core";
import { AlertButton, AlertController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(
    private readonly alertController: AlertController,
    private readonly toastController: ToastController,
  ) {}

  public async presentSuccessToast(message: string): Promise<void> {
    return this.presentToastInternal(undefined, message, "top", "success");
  }

  public async presentErrorToast(message: string): Promise<void> {
    return this.presentToastInternal(undefined, message, "top", "error");
  }

  public async presentSuccessToastWithTitle(
    title: string,
    message: string,
    position?: "top" | "bottom" | "middle",
  ): Promise<void> {
    return this.presentToastInternal(title, message, position, "success");
  }

  public async presentAlert(
    header: string,
    subHeader: string,
    message: string,
    handler?: () => void,
    handlerLabel?: string,
    cancelHandler?: () => void,
  ): Promise<HTMLIonAlertElement> {
    const buttons: AlertButton[] = [
      {
        text: handlerLabel ?? "Ok",
        handler,
      },
    ];

    if (!!handler) {
      buttons.unshift({
        text: "Cancel",
        role: "cancel",
        handler: cancelHandler,
      });
    }

    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      cssClass: ["text-selectable"],
      buttons: buttons,
    });

    await alert.present();

    return alert;
  }

  public async presentToastInternal(
    title: string,
    message: string,
    position: "top" | "bottom" | "middle",
    type: "success" | "error",
  ): Promise<void> {
    const toast = await this.toastController.create({
      buttons:
        type === "error"
          ? [
              {
                text: "Dismiss",
                role: "cancel",
              },
            ]
          : undefined,
      color: type === "error" ? "danger" : "success",
      duration: type === "success" ? 2000 : 0,
      header: title,
      message,
      position: position,
    });

    await toast.present();
  }
}
