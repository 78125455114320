import _ from "lodash";

export function deleteUndefinedPropertiesFromObject<Type>(object: Type): Type {
  const cleanObject = { ...object };

  Object.keys(cleanObject).forEach((key) => {
    const value = cleanObject[key];

    if (value === undefined) {
      delete cleanObject[key];
    } else if (_.isObject(value) && !Array.isArray(value)) {
      deleteUndefinedPropertiesFromObject(cleanObject[key]);
    }
  });

  return cleanObject;
}

export function removeAllCommas<T>(object: T): T {
  const cleanObject = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof object[key] === "string") {
      cleanObject[key] = value.replace(",", "");
    } else {
      cleanObject[key] = value;
    }
  }

  return cleanObject as T;
}

function isObject(input: unknown): input is object {
  return typeof input === "object" && !Array.isArray(input) && input !== null;
}

export function isObjectWithProperties<T extends object>(entity: unknown, ...properties: (keyof T)[]): entity is T {
  if (!isObject(entity)) {
    return false;
  }

  for (const property of properties) {
    if (!(property in entity)) {
      return false;
    }
  }

  return true;
}
