import { Injectable } from "@angular/core";
import { OrderHistory } from "src/services/history/history.types";
import { apiPath, orderHistoryEndpoint } from "../../shared/api-path.const";
import {
  OldOrderTime,
  OrderTime,
} from "../../app/redesign/components/form-field/order-time-form-field/order-time.enum";
import { OrderStatus, OrderStatusReverse } from "src/services/order/order-status.type";
import { BackendBody } from "../../shared/backend-api.types";
import { logSentry } from "src/services/utils/sentry.utils";
import { ApiService } from "../http/api.service";

@Injectable({
  providedIn: "root",
})
export class OrderHistoryService {
  constructor(private readonly apiService: ApiService) {}

  public async getHistoryByOrderId(orderId: string): Promise<OrderHistory[]> {
    return this.apiService.sendGetRequest<OrderHistory[]>(`${apiPath.order}/${orderId}/${apiPath.history}`, undefined);
  }

  public async logAcceptOrder(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.acceptOrder}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logAddressUpdate(orderId: string, address: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.updateAddress}`;
    return this.trySendLogRequest<BackendBody["LogUpdateAddressArgs"]>(path, {
      address,
    });
  }

  public async logAddedToCalendar(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.addedToCalendar}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logAssignDriver(orderId: string, driverId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.assignDriver}`;
    return this.trySendLogRequest<BackendBody["LogAssignDriverArgs"]>(path, {
      driverId,
    });
  }

  public async logCreateOrder(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.create_order}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logDeliveryCompleted(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.deliveryCompleted}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logDeliveryStarted(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.deliveryStarted}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logFulfillmentDatetimeUpdate(
    orderId: string,
    date: Date,
    time: OrderTime | OldOrderTime,
  ): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.updateFulfillmentDatetime}`;
    return this.trySendLogRequest<BackendBody["LogUpdateDatetimeArgs"]>(path, {
      date: date.toISOString(),
      time: time,
    });
  }

  public async logPickupCompleted(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.pickupCompleted}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logPickupStarted(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.pickupStarted}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logRejectOrder(orderId: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.rejectOrder}`;
    return this.trySendLogRequest<undefined>(path, undefined);
  }

  public async logStatusUpdate(orderId: string, status: OrderStatus): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.updateStatus}`;
    return this.trySendLogRequest<BackendBody["LogUpdateOrderStatusArgs"]>(path, {
      status: OrderStatusReverse[status],
    });
  }

  public async logUploadPicture(orderId: string, pictureUrl: string): Promise<void> {
    const path = `${apiPath.order}/${orderId}/${apiPath.history}/${orderHistoryEndpoint.uploadPicture}`;
    return this.trySendLogRequest<BackendBody["LogUploadPictureArgs"]>(path, {
      pictureUrl,
    });
  }

  private async trySendLogRequest<T>(path: string, body: T): Promise<void> {
    this.apiService
      .sendPostRequest<T, never>(path, body)
      .then(() => {})
      .catch((error) => logSentry(error));
  }
}
