import { Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";
import {
  adminPages,
  driverPages,
  editUserPage,
  kioskPages,
  myClosetPage,
  oldPages,
  redesignPages,
  userDetailPage,
} from "src/services/navigation/paths.const";
import { deliveryIdParam, pickupIdParam } from "../../app/redesign/customer/pay-for-delivery/pay-for-delivery.page";
import { FullTripOrderIds } from "src/services/order/order.interface";
import { queryParams } from "src/services/navigation/query-params.const";
import { PlanUniqueId } from "../subscription-type/subscription-type.interface";
import { ScanMode } from "../../app/redesign/admin/scan/items/scan-items.page";
import { LoginMode } from "../../app/redesign/customer/login/enter-email/enter-email.page";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private readonly navController: NavController) {}

  public async goToAddPaymentMethodPage(): Promise<void> {
    await this.goTo(redesignPages.addPaymentMethod);
  }

  public async goToAddUserPage(): Promise<void> {
    await this.goTo(editUserPage);
  }

  public async goToAllItemsPage(): Promise<void> {
    await this.goTo(adminPages.allItems);
  }

  public async goToAllOrdersPage(): Promise<void> {
    await this.goTo(adminPages.allOrders);
  }

  public async goBack(): Promise<void> {
    this.navController.back();
  }

  public async goToChooseBasePage(): Promise<void> {
    await this.goTo(redesignPages.chooseBase);
  }

  public async goToChoosePlanPage(): Promise<void> {
    await this.goTo(redesignPages.choosePlan);
  }

  public async goToCreateItemForOrderPage(orderId: string): Promise<void> {
    await this.goTo(redesignPages.itemDetails, { [queryParams.orderId]: orderId });
  }

  public async goToCreateItemForUserPage(orderId: string): Promise<void> {
    await this.goTo(redesignPages.itemDetails, { [queryParams.userId]: orderId });
  }

  public async goToCreateOrderPage(): Promise<void> {
    await this.goTo(adminPages.orderDetails);
  }

  public async goToDeliveriesPage(): Promise<void> {
    await this.goTo(redesignPages.deliveries);
  }

  public async goToDeliverySteps(deliveryId: string): Promise<void> {
    await this.goToWithPathParam(`${driverPages.orderStepsRoot}/${driverPages.deliverySteps}`, deliveryId);
  }

  public async goToDeliveryDetailPage(deliveryId: string): Promise<void> {
    await this.goToWithPathParam(redesignPages.deliveryDetail, deliveryId);
  }

  public async goToEnterEmailPage(
    loginMode: LoginMode,
    kioskPlanId?: PlanUniqueId,
    kioskOrderId?: string,
  ): Promise<void> {
    await this.goTo(redesignPages.enterEmail, {
      [queryParams.loginMode]: loginMode,
      [queryParams.planId]: kioskPlanId,
      [queryParams.orderId]: kioskOrderId,
    });
  }

  public async goToEditUserPage(userId: string): Promise<void> {
    await this.goToWithPathParam(editUserPage, userId);
  }

  public async goToItemDetailsPage(itemId: string): Promise<void> {
    await this.goToWithPathParam(redesignPages.itemDetails, itemId);
  }

  public async goToItemsToHotelPage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.itemsToHotel, orderId);
  }

  public async goToItemsToOnboardPage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.itemsToOnboard, orderId);
  }

  public async goToItemsToStagePage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.itemsToStage, orderId);
  }

  public async goToItemsToVehiclePage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.itemsToVehicle, orderId);
  }

  public async goToKioskChoosePlanPage(pendingOrderId: string): Promise<void> {
    await this.goTo(kioskPages.choosePlan, { [queryParams.pickupOrderId]: pendingOrderId });
  }

  public async goToKioskConfirmationPage(planId: PlanUniqueId, pendingOrderId: string): Promise<void> {
    await this.goTo(kioskPages.confirmation, {
      [queryParams.pickupOrderId]: pendingOrderId,
      [queryParams.planId]: planId,
    });
  }

  public async goToKioskLandingPage(): Promise<void> {
    await this.goTo(kioskPages.landing);
  }

  public async goToKioskSignUpPage(pendingOrderId: string, planId: PlanUniqueId): Promise<void> {
    await this.goTo(kioskPages.signUp, {
      [queryParams.pickupOrderId]: pendingOrderId,
      [queryParams.planId]: planId,
    });
  }

  public async goToLandingPage(): Promise<void> {
    await this.goTo(redesignPages.landing);
  }

  public async goToLoginPage(): Promise<void> {
    await this.goTo(redesignPages.login);
  }

  public async goToLoginWithPasswordPage(
    userId: string,
    kioskPlanId?: PlanUniqueId,
    kioskOrderId?: string,
  ): Promise<void> {
    await this.goToWithPathParam(redesignPages.loginWithPassword, userId, {
      [queryParams.planId]: kioskPlanId,
      [queryParams.orderId]: kioskOrderId,
    });
  }

  public async goToHomePage(): Promise<void> {
    await this.goTo(redesignPages.homePage);
  }

  public async goToManagePaymentMethodsPage(): Promise<void> {
    await this.goTo(redesignPages.managePaymentMethods);
  }

  public async goToMyAccountPage(): Promise<void> {
    await this.goTo(redesignPages.myAccount);
  }

  public async goToMyClosetPage(): Promise<void> {
    await this.goTo(myClosetPage);
  }

  public async goToNotificationsPage(): Promise<void> {
    await this.goTo(redesignPages.notifications);
  }

  public async goToNotFoundPage(): Promise<void> {
    await this.goTo(redesignPages.notFound);
  }

  public async goToOrderDetailsPage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.orderDetails, orderId);
  }

  public async goToPayForDeliveryPage(pendingOrderIds: FullTripOrderIds): Promise<void> {
    await this.goTo(redesignPages.payForDelivery, {
      [deliveryIdParam]: pendingOrderIds.deliveryOrderId,
      [pickupIdParam]: pendingOrderIds.pickupOrderId,
    });
  }

  public async goToPaymentHistoryPage(): Promise<void> {
    await this.goTo(redesignPages.paymentHistory);
  }

  public async goToPickPasswordPage(email: string, kioskPlanId?: string, kioskOrderId?: string): Promise<void> {
    await this.goTo(redesignPages.pickPassword, {
      [queryParams.email]: email,
      [queryParams.planId]: kioskPlanId,
      [queryParams.orderId]: kioskOrderId,
    });
  }

  public async goToPickupSteps(pickupId: string): Promise<void> {
    await this.goToWithPathParam(`${driverPages.orderStepsRoot}/${driverPages.pickupSteps}`, pickupId);
  }

  public async goToRequestPickupPage(): Promise<void> {
    await this.goTo(redesignPages.requestPickup);
  }

  public async goToResetPasswordPage(): Promise<void> {
    await this.goTo(redesignPages.resetPassword);
  }

  public async goToRegisterPage(): Promise<void> {
    await this.goTo(redesignPages.register);
  }

  public async goToStagingSuccessPage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.stagingSuccess, orderId);
  }

  public async goToStagingSummaryPage(orderId: string): Promise<void> {
    await this.goToWithPathParam(adminPages.stagingSummary, orderId);
  }

  public async goToScanItemsPage(orderId: string, mode: ScanMode): Promise<void> {
    await this.goToWithPathParam(adminPages.scanItems, orderId, { [queryParams.scanMode]: mode });
  }

  public async goToScanRackForItemPage(itemId: string): Promise<void> {
    await this.goTo(adminPages.scanRack, { [queryParams.itemId]: itemId });
  }

  public async goToScanRackForOrderPage(orderId: string): Promise<void> {
    await this.goTo(adminPages.scanRack, { [queryParams.orderId]: orderId });
  }

  public async goToScanVehiclePage(orderId: string): Promise<void> {
    await this.goTo(adminPages.scanVehicle, { [queryParams.orderId]: orderId });
  }

  public async goToScheduleTripPage(): Promise<void> {
    await this.goTo(oldPages.scheduleTrip);
  }

  public async goToSetInitialPasswordPage(userId?: string): Promise<void> {
    await this.goToWithPathParam(redesignPages.setInitialPassword, userId);
  }

  public async goToUserDetailsPage(userId: string): Promise<void> {
    await this.goToWithPathParam(userDetailPage, userId);
  }

  private async goTo(target: string, queryParams?: Record<string, string>): Promise<void> {
    await this.navController.navigateForward([target], { queryParams: queryParams });
  }

  private async goToWithPathParam(
    target: string,
    pathParam: string,
    queryParams?: Record<string, string>,
  ): Promise<void> {
    await this.navController.navigateForward([target, pathParam], { queryParams: queryParams });
  }
}
