import { Role } from "src/services/user/role.enum";
import { tryParseDate } from "../utils/timestamp.utils";

export enum HowDidYouHearAboutUs {
  referralFromFriendsOrFamily = "referralFromFriendsOrFamily",
  socialMedia = "socialMedia",
  googleSearch = "googleSearch",
  digitalAdvertising = "digitalAdvertising",
  foundYourSuvsOrEmployees = "foundYourSuvsOrEmployees",
  hotelConcierge = "hotelConcierge",
  blog = "blog",
  other = "other",
}

export const doNotAskUserWhereTheyHeardAboutUsIfCreatedAtAfter = new Date(2024, 10, 1);
export function shouldAskWhereUserHeardAboutUs(user: User | undefined): boolean {
  if (!user) {
    return false;
  }

  return !user.howDidYouHearAboutUs && tryParseDate(user.createdAt) > doNotAskUserWhereTheyHeardAboutUsIfCreatedAtAfter;
}

export interface User {
  company?: string;
  createdAt?: any;
  email: string;
  id?: string;
  firstName: string;
  hasActiveSubscription?: boolean;
  hasMissingPayment?: boolean;
  hasToSetInitialPassword?: boolean;
  howDidYouHearAboutUs?: HowDidYouHearAboutUs;
  lastName: string;
  overseer?: string;
  phone: string;
  providerId?: string;
  pushToken?: string;
  role: Role;
  stripeId?: string;
  tags?: string[];
  updatedAt?: Date;
}

export interface UserAdditionalData {
  firstName: string;
  lastName: string;
  company?: string;
  phoneNumber: string;
  providerId: string;
}

export const placeholderName = "User without name";

export function userHasDefaultName(firstName: string | undefined, lastName: string | undefined): boolean {
  if (!firstName || !lastName) {
    return false;
  }

  return (
    firstName && lastName && `${firstName.trim()} ${lastName.trim()}`.toLowerCase() === placeholderName.toLowerCase()
  );
}

export function userToString(user: User): string {
  if (!user) {
    return "No user found";
  }

  if (!user.firstName && !user.lastName) {
    return placeholderName;
  }

  return `${user.firstName ?? ""} ${user.lastName ?? ""}`;
}

export function isUser(input: object): input is User {
  return "email" in input && "firstName" in input && "lastName" in input;
}
