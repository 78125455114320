import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "src/services/auth/auth.service";
import { UserService } from "src/services/user/user.service";
import { Role } from "src/services/user/role.enum";

@Injectable()
export class IsAdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public userService: UserService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = await this.authService.getActiveUser();

    if (currentUser?.role === Role.admin) {
      return true;
    } else {
      let target = "/";

      await this.router.navigate([target]);
      return false;
    }
  }
}
