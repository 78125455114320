import { Injectable } from "@angular/core";
import { PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { AuthService } from "src/services/auth/auth.service";
import { MessageService } from "src/services/message/message.service";
import { Capacitor } from "@capacitor/core";
import { ApiService } from "../http/api.service";
import { BackendArguments, BackendBody } from "../../shared/backend-api.types";
import { apiPath } from "../../shared/api-path.const";

type NotificationArgs = BackendArguments["StandardNotificationController_getNotifications"]["parameters"]["query"];
type NotificationPage = BackendBody["NotificationDataPage"];

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly messageService: MessageService,
  ) {}

  public async getNotifications(): Promise<NotificationPage> {
    return this.apiService.sendGetRequest<NotificationPage, NotificationArgs>(apiPath.notification, {
      first: "50",
      offset: "0",
    });
  }

  public async markAllNotificationsAsSeen(userId: string): Promise<void> {
    await this.apiService.sendPutRequest<unknown, never>(`${apiPath.notification}/${userId}/all_seen`, {});
  }

  public async listenForNotifications(): Promise<void> {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

    if (!isPushNotificationsAvailable) {
      console.log("Notifications unavailable");
      return;
    }

    console.log("Notifications available");

    await PushNotifications.addListener("registration", async (token) => {
      console.log(token);
      await this.authService.savePushToken(token.value);
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema): Promise<void> => {
        await this.messageService.presentSuccessToastWithTitle(notification.title, notification.body);
      },
    );

    await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
      console.log("Push notification action performed", notification.actionId, notification.inputValue);
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  }
}
