type Timestamp = {
  toDate: () => Date;
};

export function tryParseDate(input: Date | string | undefined): Date | undefined {
  if (!input) {
    return undefined;
  } else if (typeof input === "string") {
    return new Date(input);
  } else if (input instanceof Date) {
    return input;
  } else if (typeof input["toDate"] === "function") {
    return (input as Timestamp).toDate();
  } else {
    return new Date(input);
  }
}
