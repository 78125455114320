import { Routes } from "@angular/router";
import { redesignPages } from "src/services/navigation/paths.const";
import { HasValidSubscriptionGuard } from "../../services/router-guards/has-valid-subscription.guard";
import {
  fireAuthGuardLoggedInConfig,
  fireAuthGuardUnauthorizedConfig,
  guardsForAuthenticatedPages,
} from "./routes-config.const";
import { pathParams } from "src/services/navigation/query-params.const";
import { KioskGuard } from "src/services/router-guards/kiosk.guard";
import { sendAdminsAndDriversToAdminApp } from "src/services/router-guards/send-admins-and-drivers-to-admin-app.guard";
import { RedirectToDownloadAppOnDesktopGuard } from "src/services/router-guards/redirect-to-download-app-on.desktop.guard";

export const redesignRoutes: Routes = [
  {
    path: redesignPages.addPaymentMethod,
    loadChildren: () =>
      import("../redesign/customer/add-payment-method/add-payment-method.module").then(
        (m) => m.ManagePaymentMethodsModule,
      ),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.chooseBase,
    loadChildren: () => import("../redesign/customer/choose-base/choose-base.module").then((m) => m.ChooseBaseModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.choosePlan,
    loadChildren: () => import("../redesign/customer/choose-plan/choose-plan.module").then((m) => m.ChoosePlanModule),
    canActivate: [RedirectToDownloadAppOnDesktopGuard, KioskGuard],
  },
  {
    path: redesignPages.deliveries,
    loadChildren: () => import("../redesign/customer/deliveries/deliveries.module").then((m) => m.DeliveriesModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${redesignPages.deliveryDetail}/:id`,
    loadChildren: () =>
      import("../redesign/customer/delivery-details/delivery-details.module").then((m) => m.DeliveryDetailsModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.enterEmail,
    loadChildren: () =>
      import("../redesign/customer/login/enter-email/enter-email.module").then((m) => m.EnterEmailModule),
    canActivate: [KioskGuard],
  },
  {
    path: redesignPages.homePage,
    loadChildren: () => import("../redesign/customer/home/home.module").then((m) => m.HomeModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
      sendAdminsAndDriversToAdminApp,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.itemDetails,
    loadChildren: () => import("../redesign/customer/item-detail/item-detail.module").then((m) => m.ItemDetailModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: `${redesignPages.itemDetails}/:${pathParams.itemId}`,
    loadChildren: () => import("../redesign/customer/item-detail/item-detail.module").then((m) => m.ItemDetailModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.landing,
    loadChildren: () => import("../redesign/customer/landing/landing.module").then((m) => m.LandingPageModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: redesignPages.login,
    loadChildren: () => import("../redesign/customer/login/login.module").then((m) => m.LoginModule),
    canActivate: [...guardsForAuthenticatedPages, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: `${redesignPages.loginWithPassword}/:${pathParams.userId}`,
    loadChildren: () =>
      import("../redesign/customer/login/login-with-password/login-with-password.module").then(
        (m) => m.LoginWithPasswordModule,
      ),
    canActivate: [...guardsForAuthenticatedPages, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: redesignPages.managePaymentMethods,
    loadChildren: () =>
      import("../redesign/customer/manage-payment-methods/manage-payment-methods.module").then(
        (m) => m.ManagePaymentMethodsModule,
      ),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.myAccount,
    loadChildren: () => import("../redesign/customer/account/account.module").then((m) => m.AccountModule),
    canActivate: [
      ...guardsForAuthenticatedPages,
      RedirectToDownloadAppOnDesktopGuard,
      KioskGuard,
      HasValidSubscriptionGuard,
    ],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.notifications,
    loadChildren: () =>
      import("../redesign/customer/notifications/notifications.module").then((m) => m.NotificationsModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.payForDelivery,
    loadChildren: () =>
      import("../redesign/customer/pay-for-delivery/pay-for-delivery.module").then((m) => m.PayForDeliveryModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.paymentHistory,
    loadChildren: () =>
      import("../redesign/customer/payment-history/payment-history.module").then((m) => m.PaymentHistoryModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.pickPassword,
    loadChildren: () =>
      import("../redesign/customer/login/set-initial-password/set-initial-password.module").then(
        (m) => m.SetInitialPasswordModule,
      ),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: redesignPages.register,
    loadChildren: () => import("../redesign/customer/register/register.module").then((m) => m.RegisterModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
  {
    path: redesignPages.requestPickup,
    loadChildren: () =>
      import("../redesign/customer/request-pickup/request-pickup.module").then((m) => m.RequestPickupPageModule),
    canActivate: [KioskGuard, RedirectToDownloadAppOnDesktopGuard],
  },
  {
    path: redesignPages.resetPassword,
    loadChildren: () =>
      import("../redesign/customer/reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: `${redesignPages.setInitialPassword}/:${pathParams.userId}`,
    loadChildren: () =>
      import("../redesign/customer/login/set-initial-password/set-initial-password.module").then(
        (m) => m.SetInitialPasswordModule,
      ),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardLoggedInConfig,
  },
  {
    path: `${redesignPages.thankYou}/:type`,
    loadChildren: () => import("../redesign/customer/thank-you/thank-you.module").then((m) => m.ThankYouModule),
    canActivate: [...guardsForAuthenticatedPages, RedirectToDownloadAppOnDesktopGuard, KioskGuard],
    data: fireAuthGuardUnauthorizedConfig,
  },
];
