import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
import { firstValueFrom, Observable } from "rxjs";
import { Order } from "src/services/order/order.interface";
import { tryParseDate } from "src/services/utils/timestamp.utils";
import { BaseCollectionService } from "src/services/base-collection.service";
import { OrderHistoryService } from "src/services/history/order-history.service";
import { environment } from "@environments/environment";
import { OrderStatus } from "src/services/order/order-status.type";

export enum OrderType {
  active = "active",
  pending = "pending",
}

@Injectable({
  providedIn: "root",
})
export class OrderService extends BaseCollectionService<Order> {
  constructor(
    protected readonly firestore: AngularFirestore,
    private readonly orderHistoryService: OrderHistoryService,
  ) {
    super(firestore, environment.collections.orders);
  }

  public getAllOrdersForUserIdThisSeason(userId: string): Observable<Order[]> {
    return this.getByUserId(userId).pipe(
      map((actions) => actions.filter((order: Order) => this.isOrderFromCurrentSeason(order))),
    );
  }

  public getLastFulfilledOrderForUserId(userId: string): Observable<Order | undefined> {
    const collection = this.firestore.collection<Order>(environment.collections.orders, (ref) => {
      const doNotAskForReviewOnOldOrders = new Date(2024, 10, 1);
      let query = ref
        .where("status", "==", OrderStatus.completed)
        .where("userId", "==", userId)
        .where("createdAt", ">", doNotAskForReviewOnOldOrders);

      query.limit(1);

      query.orderBy("fulfillmentDate", "desc");

      return query;
    });

    return this.returnFirstDocument(collection);
  }

  public async set(doc: Order): Promise<string> {
    const newOrderId = await super.set(doc);

    await this.orderHistoryService.logCreateOrder(newOrderId);

    return newOrderId;
  }

  public async update(docId: string, doc: Partial<Order>): Promise<void> {
    const currentOrder = await firstValueFrom(this.getById(docId));
    await this.logRequiredEvents(docId, doc, currentOrder);

    if (doc.driverId && currentOrder.driverId !== doc.driverId) {
      doc.driverAccepted = false;
    }

    return super.update(docId, doc);
  }

  public async search(searchTerm: string): Promise<Order[]> {
    if (!searchTerm) {
      return;
    }

    return (await firstValueFrom(this.getAll())).filter((order) =>
      Object.values(order).some(
        (value) => !!value && typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }

  private isOrderFromCurrentSeason(order: Order): boolean {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const currentlyInNovOrDec = currentMonth === 10 || currentMonth === 11;

    const seasonStart = new Date(currentlyInNovOrDec ? currentYear : currentYear - 1, 10, 1);
    const seasonEnd = new Date(currentlyInNovOrDec ? currentYear + 1 : currentYear, 9, 31);

    const parsedDate = tryParseDate(order.fulfillmentDate).toISOString().split("-");
    const orderDate = new Date(parseInt(parsedDate[0]), parseInt(parsedDate[1]) - 1, parseInt(parsedDate[2]));

    return orderDate > seasonStart && orderDate < seasonEnd && order.status !== OrderStatus.cancelled;
  }

  private async logRequiredEvents(docId: string, doc: Partial<Order>, currentOrder: Order): Promise<void> {
    if (!currentOrder) {
      return;
    }

    if (doc.driverId && doc.driverId !== currentOrder.driverId) {
      await this.orderHistoryService.logAssignDriver(docId, doc.driverId);
    }

    if (doc.address?.formatted_address && doc.address?.formatted_address !== currentOrder.address?.formatted_address) {
      await this.orderHistoryService.logAddressUpdate(docId, doc.address.formatted_address);
    }

    if (
      doc.fulfillmentDate &&
      doc.fulfillmentTime &&
      (doc.fulfillmentDate !== currentOrder.fulfillmentDate || doc.fulfillmentTime !== currentOrder.fulfillmentTime)
    ) {
      await this.orderHistoryService.logFulfillmentDatetimeUpdate(
        docId,
        new Date(doc.fulfillmentDate),
        doc.fulfillmentTime,
      );
    }

    if (doc.status && doc.status !== currentOrder.status) {
      await this.orderHistoryService.logStatusUpdate(docId, doc.status);
    }
  }
}
