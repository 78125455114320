import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor(private readonly loadingController: LoadingController) {}

  public async presentLoading(message: string): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingController.create({ message });
    await loading.present();

    return loading;
  }
  public async wrapInLoading<T>(operation: () => T | Promise<T>, message?: string): Promise<T> {
    const loading = await this.loadingController.create({ message });
    await loading.present();

    try {
      return await operation();
    } finally {
      await loading.dismiss();
    }
  }
}
