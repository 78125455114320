<ion-content color="light">
  <ng-container>
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-xl="6">
          <ion-row>
            <ion-col>
              <rc-page-title
                [title]="cost > 0 ? 'Payment required' : 'Cost covered in your subscription'"
                [showGoToHome]="true"></rc-page-title>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="cost > 0">
            <ion-col>
              <ion-text>Every order outside of your plan adds a shipping cost of 70$ per item</ion-text>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="loading">
            <ion-skeleton-text class="price-skeleton" [animated]="true" *ngIf="loading"></ion-skeleton-text>
          </ion-row>

          <ng-container *ngIf="!loading">
            <ion-row>
              <ion-col>
                <rc-payment-reason
                  *ngIf="handlingPaymentOfDelivery"
                  [orderInfo]="deliveryOrderInfo"></rc-payment-reason>
                <rc-payment-reason *ngIf="handlingPaymentOfPickup" [orderInfo]="pickupOrderInfo"></rc-payment-reason>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-margin-vertical">
                <ion-label *ngIf="!loading"><h2>Total: {{cost}}.00$</h2></ion-label>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-text-center">
                <ion-button *ngIf="cost > 0" color="secondary" (click)="pay()">
                  <ion-icon name="card-outline" slot="start"></ion-icon>
                  Proceed with payment
                </ion-button>
                <ion-button *ngIf="cost === 0" color="secondary" (click)="promote()">
                  <ion-icon name="checkmark-circle-outline" slot="start"></ion-icon>
                  Confirm
                </ion-button>
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-content>
