import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { Referral } from "src/services/referral/referral.interface";
import { BaseCollectionService } from "../base-collection.service";

export const referralQueryParamKey = "referralCode";
export const referralLocalStorageKey = "referralCode";

@Injectable({
  providedIn: "root",
})
export class ReferralService extends BaseCollectionService<Referral> {
  constructor(protected readonly firestore: AngularFirestore) {
    super(firestore, environment.collections.referrals);
  }

  public async referralCodeExists(referralCode: string): Promise<boolean> {
    return (await this.count("referralPromoCode", "==", referralCode.toUpperCase())) > 0;
  }

  public async getReferralByReferralCode(referralCode: string): Promise<Referral | undefined> {
    return (await firstValueFrom(this.getWhere("referralPromoCode", "==", referralCode.toUpperCase())))[0];
  }

  public async getReferralByUserId(userId: string): Promise<Referral | undefined> {
    return (await firstValueFrom(this.getWhere("userId", "==", userId)))[0];
  }

  public getDiscountToApply(referringUserId: string): Observable<number> {
    const referredUsersCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("referredByUserId", "==", referringUserId),
    );

    const referredByCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("userId", "==", referringUserId),
    );

    return combineLatest([referredUsersCollection.snapshotChanges(), referredByCollection.snapshotChanges()]).pipe(
      map((actions) => {
        const percentDiscountPerReferral = 10;
        const referredUsers = actions[0].length;

        const userOwnReferral = actions[1].length > 0 ? (actions[1][0].payload.doc.data() as Referral) : undefined;

        let wasReferredByAUser = false;
        if (userOwnReferral) {
          wasReferredByAUser = !!userOwnReferral.referredByUserId;
        }

        const usersToConsiderForDiscount = wasReferredByAUser ? referredUsers + 1 : referredUsers;

        return Math.min(usersToConsiderForDiscount * percentDiscountPerReferral, 100);
      }),
    );
  }
}
