import { HttpErrorResponse } from "@angular/common/http";
import { ErrorResponseModel } from "../../../functions/src/interfaces/error-model.interface";
import { isObjectWithProperties } from "../utils/object.utils";

export function isHttpError(error: unknown): error is HttpErrorResponse {
  return !!error && typeof error === "object" && "error" in error;
}

export function isKnownApiError(error: unknown): error is ErrorResponseModel {
  return (
    isObjectWithProperties(error, "errorCode") &&
    typeof error.errorCode === "string" &&
    isObjectWithProperties(error, "errorMessage") &&
    typeof error.errorMessage === "string"
  );
}
