import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { kioskPages, kioskRoot, redesignPages, routesPartOfKioskFlow } from "src/services/navigation/paths.const";
import { isInKioskMode } from "src/services/utils/kiosk-mode.utils";

@Injectable()
export class KioskGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<boolean> {
    const currentRoute = route.url.toString();

    if (isInKioskMode()) {
      return this.handleKioskFlowRedirection(currentRoute);
    } else {
      return this.handleNonKioskFlowRedirection(currentRoute);
    }
  }

  private async handleKioskFlowRedirection(currentRoute: string): Promise<boolean> {
    const routeIsOnKioskPath = this.routeIsOnKioskPath(currentRoute);
    if (routeIsOnKioskPath) {
      return true;
    } else if (routesPartOfKioskFlow.some((route) => currentRoute.startsWith(route))) {
      return true;
    } else {
      await this.router.navigate([kioskPages.landing]);
      return false;
    }
  }

  private async handleNonKioskFlowRedirection(currentRoute: string): Promise<boolean> {
    const routeIsOnKioskPath = this.routeIsOnKioskPath(currentRoute);
    if (!routeIsOnKioskPath) {
      return true;
    } else {
      await this.router.navigate([redesignPages.landing]);
      return false;
    }
  }

  private routeIsOnKioskPath(currentRoute: string): boolean {
    return currentRoute.startsWith(kioskRoot);
  }
}
