import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Role } from "src/services/user/role.enum";
import { AuthService } from "src/services/auth/auth.service";
import { Injectable } from "@angular/core";
import { adminPages, customerPages } from "src/services/navigation/paths.const";
import { User } from "src/services/user/user.interface";
import { promiseWithTimeout } from "src/services/utils/promise.utils";
import { isInKioskMode } from "src/services/utils/kiosk-mode.utils";
import { edition } from "@environments/edition";

@Injectable()
export class RedirectToBlockedBasedOnRoleAndModeGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<boolean> {
    if (isInKioskMode()) {
      return true;
    }

    if (edition.mode === "customerAndStaff") {
      return true;
    }

    let currentUser: User;
    try {
      currentUser = await promiseWithTimeout(this.authService.getActiveUser(), 1000, "timeout fetching user");
    } catch {
      currentUser = await this.authService.getActiveUser();
    }

    if (!currentUser) {
      return true;
    }

    const isStaff = currentUser?.role === Role.admin || currentUser?.role === Role.driver;
    const isStaffInStaffMode = isStaff && edition.mode === "staff";
    const isCustomerInCustomerMode = !isStaff && edition.mode === "customer";

    const isAllowedForThisMode = isStaffInStaffMode || isCustomerInCustomerMode;
    if (isAllowedForThisMode) {
      return true;
    }

    if (edition.mode === "staff") {
      return this.router.navigate([customerPages.adminOnly]);
    } else {
      return this.router.navigate([adminPages.customerOnly]);
    }
  }
}
