export const kioskModeStorageKey = "kioskModeEnabled";

export function isInKioskMode(): boolean {
  return localStorage.getItem(kioskModeStorageKey) === "true";
}

export function enableKioskMode(): void {
  localStorage.setItem(kioskModeStorageKey, "true");
}

export function disableKioskMode(): void {
  localStorage.removeItem(kioskModeStorageKey);
}
