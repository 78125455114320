export enum OrderStatus {
  cancelled = "Cancelled",
  completed = "Completed",
  inProgress = "In Progress",
  new = "New",
  pending = "Pending",
}

export const orderStatusCompletion: OrderStatus[] = [OrderStatus.completed, OrderStatus.cancelled];

export enum OrderStatusReverse {
  "Cancelled" = "cancelled",
  "Completed" = "completed",
  "In Progress" = "inProgress",
  "New" = "new",
}
