import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "@environments/environment";
import { BaseCollectionService, BaseDocumentBeforeCreate } from "src/services/base-collection.service";
import { PendingOrder } from "src/services/pending-order/pending-order.interface";
import { OrderHistoryService } from "src/services/history/order-history.service";

@Injectable({
  providedIn: "root",
})
export class PendingOrderService extends BaseCollectionService<PendingOrder> {
  constructor(
    protected readonly firestore: AngularFirestore,
    private readonly orderHistoryService: OrderHistoryService,
  ) {
    super(firestore, environment.collections.pendingOrders);
  }

  public override async set(doc: BaseDocumentBeforeCreate<PendingOrder>): Promise<string> {
    const newOrderId = await super.set(doc);

    await this.orderHistoryService.logCreateOrder(newOrderId);

    return newOrderId;
  }
}
