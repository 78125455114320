import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/compat/auth-guard";
import { redesignPages } from "src/services/navigation/paths.const";
import { RedirectToBlockedBasedOnRoleAndModeGuard } from "../../services/router-guards/redirect-to-blocked-based-on-role-and-mode.guard";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([redesignPages.landing]);
const redirectLoggedInToHome = () => redirectLoggedInTo([redesignPages.homePage]);

export const fireAuthGuardUnauthorizedConfig = { authGuardPipe: redirectUnauthorizedToLogin };
export const fireAuthGuardLoggedInConfig = { authGuardPipe: redirectLoggedInToHome };

export const guardsForAuthenticatedPages = [AngularFireAuthGuard, RedirectToBlockedBasedOnRoleAndModeGuard];
